import { createLogger } from 'vue-logger-plugin'

// create logger with options
const log = createLogger({
  enabled: true,
  level: 'debug',
  beforeHooks: [ ],
  afterHooks: [ ],
  prefixFormat: ({ level, caller }) => (
    caller
      ? `[${level.toUpperCase()}] [${caller?.fileName}:${caller?.functionName}:${caller?.lineNumber}]`
      : `[${level.toUpperCase()}]`
  ),
})

export default log