
class Http {
  env = process.env.NODE_ENV
  versionConf = "/version/conf"
  userRegister = "/user/register"
  userLogin = "/user/login"
  tokenBind = "user/token_bind"
  mailSend = "/mail/send"
  lobbyConf = "/lobby/conf"

}
class Topic{
  payInfo = 'pay/HD_payInfo'
  userInfo = 'lobby/HD_GetBasicInfo'
  bankList = 'pay/HD_douDouBtList'
  tcpLogin = "lobby/HD_login"
  wallet = "lottery/HD_Wallet"
  bindBank = 'pay/HD_BindBtCard'
  withDraw = 'pay/HD_douDou'
  mailList = 'lobby/HD_GetMailList'
  config = "lottery/HD_info2"
  record = "lottery/HD_record"
  syncTime = "lottery/HD_syncTime"
  addBet2 = "lottery/HD_addBet2"
  getBetRecordList = "lottery/HD_getBetRecordList"
  getShortURL = "lobby/HD_GetShortURL"
  getSelfLink = "conv/HD_GetSelfLink"
  deleteShortLink = "conv/HD_DeleteShortLink"
  updateShortLink = "conv/HD_UpdateShortLink"
  getLinkData = "conv/HD_GetLinkData"
  getLinkVisitRecord = "conv/HD_GetLinkVisitRecord"
  getPersonalAgentInfo = "lobby/HD_GetPersonalAgentInfo"
  getTeamAgentInfo = "lobby/HD_GetTeamAgentInfo"
  getPersonalAgentRecord = "lobby/HD_GetPersonalAgentRecord"
  getTeamAgentRecord = "lobby/HD_GetTeamAgentRecord"
  getSelfTotalData = "conv/HD_GetSelfTotalData"
  getPlatTotalData = "conv/HD_GetPlatTotalData"
  getAllUsers = "conv/HD_GetAllUsers"
  getAllLinks = "conv/HD_GetAllLinks"
  getAllLinkData = "conv/HD_GetAllLinkData"
  getLinkVisitRecord = "conv/HD_GetLinkVisitRecord"
  hijackLinkConf = "conv/HD_HijackLinkConf"
  getHijackList = "conv/HD_GetHijackList"
  billRecord = 'lobby/HD_GetBills'//账单记录
  adminMailSend = "lobby/HD_adminMailSend"
  deleteMail = 'lobby/HD_DeleteMail'
  agentConf = 'lobby/HD_agentConf'
  SetAgentConf = 'lobby/HD_SetAgentConf'
  getPayConf = 'pay/HD_GetPayConf'
  setPayConf = 'pay/HD_SetPayConf'
  getTotalReport = 'lobby/HD_GetTotalReport'
  getUserReport = 'lobby/HD_GetUserReport'
  getDoudouList = 'pay/HD_GetDoudouList'
  updateDoudou = 'pay/HD_UpdateDoudou'
  queryPersonalAgent = 'lobby/HD_QueryPersonalAgent'
  queryTeamAgent = 'lobby/HD_QueryTeamAgent'
  queryTeamData = 'lobby/HD_QueryTeamData'
  queryInviteRecord = 'lobby/HD_QueryInviteRecord'
  changePW = 'lobby/HD_ModifyPassword'
  getCouponInfo = 'pay/HD_GetCouponInfo'
  charge = 'pay/HD_charge'
  walletChargePhone = 'pay/HD_WalletChargePhone'
  getSelfCoupon = 'pay/HD_GetSelfCoupon'
  phoneOrderLog = 'pay/HD_PhoneOrderLog'
  
}
let http = new Http()
let topic = new Topic()
export  {http,topic}
