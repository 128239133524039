<template>
  <div class="template-form" :class="{active:open}" >
    <div class="form-header">
      <h3 class="form-header-title">{{$t('您的网站设置')}}</h3>
      <!-- <i class="material-symbols-outlined btn-toggle">remove</i> -->
      <div class="openOrClose" @click="open=!open">
        <img src="@/assets/pc/shangla.png" v-if="!open"  alt="" >
        <img  src="@/assets/pc/suoxiao_icon.png" v-else alt="" style="width:14px;margin-top:24px;">

      </div>
     
    </div>
    <div class="form-body" id="templateFormBody">
      <div class="form-block">
        <h3 class="form-main-title">{{$t('请务必填写每一栏位')}}</h3>
        <p>{{$t('完成表单后，下载您的配置文件并将其提供给业务专员')}}!</p>
      </div>
      <div class="form-block " :class="{active:showPc}" id="templateFormDesktop">
        <div class="form-block-header">
          <h3 class="form-title">{{$t('桌面模板')}}</h3>

          <img  class="canClick" @click="showPc=!showPc" src="@/assets/pc/suoxiao_hui.png" v-if="showPc" alt="" style="width:14px;">
          <img  class="canClick"  @click="showPc=!showPc" src="@/assets/pc/zhankai_icon.png" v-else alt="" style="width:14px;">


         
        </div>
        <div class="form-block-content">
          <div class="form-pic-info">
            <div class="left">
              <div class="pic" v-if="pcConf"><img :src="pcConf.img" alt="" /></div>
              <div class="pic" v-else><img src="@/assets/wut.png" alt="" /></div>

            </div>
            <div class="right">
              <div class="info-title">
                {{$t('桌面版')}}<span class="selected" v-if="pcConf">{{pcConf.id}}</span>
              </div>
              <button class="k-btn btn-s" @click="toPage('pc')">{{$t('浏览')}}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-block" :class="{active:showPhone}" id="templateFormMobile">
        <div class="form-block-header">
          <h3 class="form-title">{{$t('手机模板')}}</h3>
          <img  class="canClick" @click="showPhone=!showPhone" src="@/assets/pc/suoxiao_hui.png" v-if="showPhone" alt="" style="width:14px;">
          <img  class="canClick"  @click="showPhone=!showPhone" src="@/assets/pc/zhankai_icon.png" v-else alt="" style="width:14px;">

        </div>
        <div class="form-block-content">
          <div class="form-pic-info">
            <div class="left">
              <div class="pic" v-if="mobileConf"><img :src="mobileConf.img" alt="" /></div>
              <div class="pic" v-else><img src="@/assets/wut.png" alt="" /></div>

            </div>
            <div class="right">
              <div class="info-title">
                {{$t('手机版')}}<span class="selected" v-if="mobileConf">{{mobileConf.id  }}</span>
              </div>
              <button class="k-btn btn-s" @click="toPage('mobile')">{{$t('浏览')}}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-block" :class="{active:showGame}">
        <div class="form-block-header">
          <h3 class="form-title">{{$t('游戏大厅模板')}}</h3>
          <img  class="canClick" @click="showGame=!showGame" src="@/assets/pc/suoxiao_hui.png" v-if="showGame" alt="" style="width:14px;">
          <img  class="canClick"  @click="showGame=!showGame" src="@/assets/pc/zhankai_icon.png" v-else alt="" style="width:14px;">

        </div>
        <div class="form-block-content">
          <div class="form-pic-info" id="templateFormGamelobbyLive">
            <div class="left">
              <div class="pic"><img :src="casinoConf.img" alt="" /></div>
            </div>
            <div class="right">
              <div class="info-title">
                {{$t('视讯')}}<span class="selected">{{casinoConf.id}}</span>
              </div>
              <button class="k-btn btn-s" @click="toGameTable(0)">{{$t('浏览')}}</button>
            </div>
          </div>
          <div class="form-pic-info" id="templateFormGamelobbyEgame">
            <div class="left">
              <div class="pic"><img :src="sportConf.img" alt="" /></div>
            </div>
            <div class="right">
              <div class="info-title">
                {{$t('体育')}}<span class="selected">{{sportConf.id}}</span>
              </div>
              <button class="k-btn btn-s" @click="toGameTable(1)">{{$t('浏览')}}</button>
            </div>
          </div>
          <div class="form-pic-info" id="templateFormGamelobbySports">
            <div class="left">
              <div class="pic"><img :src="slotConf.img" alt="" /></div>
            </div>
            <div class="right">
              <div class="info-title">
                {{$t('老虎机')}}<span class="selected">{{slotConf.id}}</span>
              </div>
              <button class="k-btn btn-s" @click="toGameTable(2)">{{$t('浏览')}}</button>
            </div>
          </div>
          <div class="form-pic-info" id="templateFormGamelobbyMpg">
            <div class="left">
              <div class="pic">
                <img :src="cardConf.img" alt="" />
              </div>
            </div>
            <div class="right">
              <div class="info-title">
                {{$t('棋牌')}}<span class="selected">{{cardConf.id}}</span>
              </div>
              <button class="k-btn btn-s" @click="toGameTable(3)">{{$t('浏览')}}</button>
            </div>
          </div>
          <div class="form-pic-info" id="templateFormGamelobbyAnimal">
            <div class="left">
              <div class="pic"><img :src="lotteryConf.img" alt="" /></div>
            </div>
            <div class="right">
              <div class="info-title">
                {{$t('彩票')}}<span class="selected">{{lotteryConf.id}}</span>
              </div>
              <button class="k-btn btn-s" @click="toGameTable(4)">{{$t('浏览')}}</button>
            </div>
          </div>
          <div class="form-pic-info" id="templateFormGamelobbyLottery">
            <div class="left">
              <div class="pic">
                <img :src="fishConf.img" alt="" />
              </div>
            </div>
            <div class="right">
              <div class="info-title">
                {{$t('捕鱼')}}<span class="selected">{{fishConf.id}}</span>
              </div>
              <button class="k-btn btn-s" @click="toGameTable(5)">{{$t('浏览')}}</button>
            </div>
          </div>
          <div class="form-pic-info" id="templateFormGamelobbyEsports">
            <div class="left">
              <div class="pic">
                <img :src="djConf.img" alt="" />
              </div>
            </div>
            <div class="right">
              <div class="info-title">
                {{$t('斗鸡')}}<span class="selected">{{djConf.id}}</span>
              </div>
              <button class="k-btn btn-s" @click="toGameTable(6)">{{$t('浏览')}}</button>
            </div>
          </div>
          <!-- <div class="form-pic-info" id="templateFormGamelobbyChess">
            <div class="left">
              <div class="pic"><img :src="djConf.img" alt="" /></div>
            </div>
            <div class="right">
              <div class="info-title">
                棋牌<span class="selected">chess1</span>
              </div>
              <button class="k-btn btn-s">{{$t('浏览')}}</button>
            </div>
          </div> -->
        </div>
      </div>
      <div class="form-block" :class="{active:showInfo}">
        <div class="form-block-header">
          <h3 class="form-title">{{$t('基本信息')}}</h3>
          <img  class="canClick" @click="showInfo=!showInfo" src="@/assets/pc/suoxiao_hui.png" v-if="showInfo" alt="" style="width:14px;">
          <img  class="canClick"  @click="showInfo=!showInfo" src="@/assets/pc/zhankai_icon.png" v-else alt="" style="width:14px;">

        </div>
        <div class="form-block-content">
          <div class="k-input">
            <div class="input-title">{{$t('网站名称')}}</div>
            <div class="input-wrap">
              <input type="text" v-model="form.name" name="wlName"  />
            </div>
          </div>
          <div class="k-input">
            <div class="input-title">{{$t('网站 ID')}}</div>
            <div class="input-wrap">
              <input type="text" v-model="form.id" name="wlCode"  />
            </div>
            <div class="prompt-text">
              {{ $t('只允许小写字母和数字，第一个字符必须是小写字母')}}
              
            </div>
          </div>
        </div>
      </div>
      <div class="form-block" :class="{active:showLanBill}">
        <div class="form-block-header">
          <h3 class="form-title">{{$t('语言和货币')}}</h3>

          <img  class="canClick" @click="showLanBill=!showLanBill" src="@/assets/pc/suoxiao_hui.png" v-if="showLanBill" alt="" style="width:14px;">
          <img  class="canClick"  @click="showLanBill=!showLanBill" src="@/assets/pc/zhankai_icon.png" v-else alt="" style="width:14px;">


        </div>
        <div class="form-block-content">
          <div class="k-react-select">
            <div class="input-title">{{$t('网站语言')}}</div>
            <el-select
      v-model="language"
      multiple
      placeholder="Select"
      @change="changeEvent"
      style="width: 100%;margin-bottom:10px;"
    >
      <el-option
        v-for="item in lanOption"
        :teleported="false"
        :key="item.value"
        :label="item.label"
        
        :value="item.value"
      />
    </el-select>
          </div>
          <div class="k-react-select">
            <div class="input-title">{{$t('网站默认语言')}}</div>
            <el-select
            v-model="form.default_language"
            
            placeholder="Select"
            style="width: 100%;margin-bottom:10px;"
          >
            <el-option
              v-for="item in lanOption"
              :teleported="false"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
            
          </div>
          <div class="k-react-select">
            <div class="input-title">{{$t('货币')}}</div>
            <el-select
            v-model="form.money"
            
            placeholder="Select"
            style="width: 100%;margin-bottom:10px;"
          >
            <el-option
              v-for="item in moneyOption"
              :teleported="false"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          
          </div>
          <div class="k-react-select">
            <div class="input-title">{{$t('国家电话代码')}}</div>
            <el-select
            v-model="form.phoneCode"
            
            placeholder="Select"
            style="width: 100%;margin-bottom:10px;"
          >
            <el-option
              v-for="item in phoneOption"
              :teleported="false"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          </div>
        </div>
      </div>
    </div>
    <div class="form-footer" @click="downLoad()">
      <button class="k-btn btn-primary btn-block btn-submit" :class="{'btn-disabled-color':!canDown()}">
        {{$t('下载')}}
      </button>
    </div>
  </div>
</template>

<script>
import tools from "@/utils/tools";

import { bus, busName } from "@/components/core/bus";

import { http } from "@/components/protocol/api";
import { req, qs } from "@/utils/request";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {},
  data() {
    return {
      open:false,
      showPc:true,
        showPhone:true,
        showGame:true,
        showInfo:true,
        showLanBill:true,
        lanOption:[{value:'vi',label:'vi-VN'},{value:'cn',label:'zh-CN'},{value:'en',label:'en-US'}],
        moneyOption:[{value:'VND',label:'VND(K)'},{value:'USD',label:'USD'},{value:'PHP',label:'PHP'}],
        phoneOption:[{value:'vn',label:'vn'},{value:'cn',label:'cn'}],

        mobileConf:null,
        pcConf:null,
        language:'',
        casinoConf:{img:require("@/assets/model/V11/v11_casino.png"),id:'v11'},
        sportConf:{img:require("@/assets/model/V11/v11_sport.png"),id:'v11'},
        slotConf:{img:require("@/assets/model/V11/v11_slot.png"),id:'v11'},
        cardConf:{img:require("@/assets/model/V11/v11_card.png"),id:'v11'},
        lotteryConf:{img:require("@/assets/model/V11/v11_lottery.png"),id:'v11'},
        fishConf:{img:require("@/assets/model/V11/v11_fish.png"),id:'v11'},
        djConf:{img:require("@/assets/model/V11/v11_dj.png"),id:'v11'},
      form:{
        language:'',
        default_language:'',
        mobile:null,
        pc:null,
        casino:'v11',
        sports:'v11',
        slot:'v11',
        card:'v11',
        lottery:'v11',
        fish:'v11',
        dj:'v11',
        name:'',
        id:'',
        money:'',
        phoneCode:''

      }
    };
  },
  props: {},
  computed: {
    ...mapGetters("tcp", ["isLogged"]),
    ...mapGetters("global", ["lobbyInfo", "version", "staticPic"]),
  },
  created() {
    var that = this
    bus.on('mobileChoose',(val)=>{
      that.open = true
      that.mobileConf = val
      that.form.mobile = val.id

      localStorage.setItem('form',JSON.stringify(that.form))
    })
    bus.on('pcChoose',(val)=>{
      that.open = true
      that.pcConf = val
      that.form.pc = val.id

      localStorage.setItem('form',JSON.stringify(that.form))
    })
    bus.on('gameChoose',(val)=>{
      that.open = true

      if(val.type=='casino'){
        that.casinoConf = val.list[val.selectIndex]
      }else if(val.type=='sports'){
        that.sportConf = val.list[val.selectIndex]
      }else if(val.type=='slot'){
        that.slotConf = val.list[val.selectIndex]
      }else if(val.type=='card'){
        that.cardConf = val.list[val.selectIndex]
      }else if(val.type=='lottery'){
        that.lotteryConf = val.list[val.selectIndex]
      }else if(val.type=='fish'){
        that.fishConf = val.list[val.selectIndex]
      }else {
        that.djConf = val.list[val.selectIndex]
      }
     that.form[val.type]=val.list[val.selectIndex].id
      console.log('ddd',that.form)

      localStorage.setItem('form',JSON.stringify(that.form))
    })

    

    
  },
  watch: {},
  methods: {
    ...mapActions("user", ["autoLogin"]),
    ...mapActions("global", ["init"]),
    toGameTable(index){
      if(this.$route.path=='/gameTable'){
        bus.emit('gameTable',index)
        
      }else {
        this.$router.push({
        name:'gameTable',
        params:{
          index:index
        }
      })
      }
     
    },
    toPage(name){
      this.$router.push({name:name})
      this.open = false
    },
    changeEvent(e){
      this.form.language = e.join(';')
      console.log('fff',this.form)
    },  
    canDown(){
      for(var key in this.form){
        if(this.form[key]==''||this.form[key]==null){
          return false
        }
      }
        return true
    },
    downLoad(){
     if(!this.canDown()){
      return 
     }


   var jsonData_str = JSON.stringify(this.form);
// 创建一个Blob对象
var blob = new Blob([jsonData_str], {type: 'text/plain;charset=utf-8'});
// 创建一个URL对象
var url = window.URL.createObjectURL(blob);
// 创建一个a标签
var a = document.createElement('a');
a.href = url;
a.download = 'data.text';
// 模拟用户点击下载链接
var evt = document.createEvent('MouseEvents');
evt.initEvent('click', true, true);
a.dispatchEvent(evt);
    }
  },
};
</script>

<style scoped lang="scss">
.k-btn.btn-s {
  font-size: 14px;
  height: 28px;
  padding: 0 10px;
}
.k-btn.btn-block {
  width: 100%;
}
.k-btn.btn-disabled-color {
  cursor: not-allowed;
  opacity: .5;
}
.template-form.active {
  transform: none;
}
.template-form {
  background: #fff;
  border-radius: 16px 16px 0 0;
  bottom: 0;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15);
  display: flex;
  flex-direction: column;
  height: calc(100% - 80px);
  overflow: hidden;
  position: fixed;
  right: 40px;
  transform: translateY(calc(100% - 50px));
  transition: .25s ease;
  width: 350px;
  z-index: 3;
  .form-footer {
    border-top: 1px solid #dadce0;
    flex: 0 0 60px;
    padding: 10px;
}
  .input-wrap {
    background: #f5f7f9;
    border-radius: 6px;
    display: flex;
    width: 100%;
}
  .input-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
}
  .k-input {
    position: relative;
    .prompt-text {
      color: #9e9e9e;
      font-size: 12px;
      line-height: 1.4;
      padding: 5px 0;
  }
    input {
      color: #0d0c22;
      flex: 1 1;
      height: 40px;
      padding: 0 16px;
  }
}
 .k-input, .template-form .k-react-select {
    margin-bottom: 10px;
  }
  .form-pic-info {
    align-items: center;
    display: flex;
    margin-bottom: 10px;
    .right {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-left: 10px;
      width: calc(100% - 70px);
      .info-title {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 600;
        gap: 6px;
        .selected {
          color: #9e9e9e;
          font-weight: 400;
      }
    }
  }
    .pic:before {
      background: #f5f7f9;
      content: "";
      float: left;
      padding-bottom: 100%;
      width: 100%;
  }
    .pic {
      border-radius: 6px;
      overflow: hidden;
      position: relative;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      width: 70px;
      img {
        height: 100%;
        left: 0;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: top center;
        object-position: top center;
        position: absolute;
        top: 0;
        width: 100%;
    }
  }
}
  p {
    color: #9e9e9e;
    font-size: 14px;
    line-height: 1.4;
}
  .form-block.active .form-block-content {
  height: auto;
  overflow: visible;
  }
  .form-block {
    margin-bottom: 20px;
    .form-block-content {
      height: 0;
      overflow: hidden;
    }
    .form-main-title {
      font-weight: 600;
      margin-bottom: 6px;
  }
  .form-block-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .form-title {
      font-weight: 600;
  }
  }
}
  .form-body {
    flex: 1 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 16px;
  }
}
.form-header {
  align-items: center;
  background: #232320;
  color: #fff;
  display: flex;
  flex: 0 0 50px;
  height: 50px;
  justify-content: center;
  position: relative;
  .openOrClose{
    height:50px;
    width:50px;
    text-align:center;
    position: absolute;
    right: 0;
    cursor: pointer;
    top: 0;
    img{
      width:18px;
      margin-top:16px;
    }
  }
 
  .form-header-title {
    font-size: 18px;
  }
}

</style>