<template>
  <div class="page-template">
    <div class="template-main main-wrap">
      <div class="template-list">
        <div class="template-item mobile-item" v-for="(item,index) in datas">
          <div class="current" v-if="selectIndex == index"></div>
          <div class="template-name">{{item.id}}</div>
          <div
            class="template-cover"
            @mouseenter="hoverIndex = index"
            @mouseleave="hoverIndex = null"
          >
            <el-image
              class="image"
              :hide-on-click-modal="true"
              @show="showChoose = true"
              :preview-teleported="true"
              :src="item.img"
              :preview-src-list="srcList"
              :initial-index="hoverIndex"
              fit="cover"
            />
            <div class="link-cover" v-if="hoverIndex == index" >
              <button class="k-btn btn-outline-white">{{ $t("预览") }}</button>
            </div>
          </div>
          <div class="template-info" @click="choose(index)"> 
            <button class="k-btn btn-select">{{$t('选择')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tools from "@/utils/tools";

import { bus, busName } from "@/components/core/bus";

import { http } from "@/components/protocol/api";
import { req, qs } from "@/utils/request";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {},
  data() {
    return {
      hoverIndex: null,
      current: null,
      selectIndex: null,
      srcList: [],
      showChoose: false,
      datas:[{img:require("@/assets/model/V11/mobile/v11_home_m.png"),id:'v11'},
      {img:require("@/assets/model/V12/mobile/v12_home_m.png"),id:'v12'},
      {img:require("@/assets/model/V21/mobile/v21_home_m.png"),id:'v21'},
      {img:require("@/assets/model/V31/mobile/v31_home_m.png"),id:'v31'},
      {img:require("@/assets/model/V32/mobile/v32_home_m.png"),id:'v32'},
      {img:require("@/assets/model/V33/mobile/v33_home_m.png"),id:'v33'},
      {img:require("@/assets/model/V34/mobile/v34_home_m.png"),id:'v34'},
      {img:require("@/assets/model/V41/mobile/v41_home_m.png"),id:'v41'},
      {img:require("@/assets/model/V42/mobile/v42_home_m.png"),id:'v42'},
      {img:require("@/assets/model/V51/mobile/v51_home_m.png"),id:'v51'},
      {img:require("@/assets/model/V61/mobile/v61_home_m.png"),id:'v61'},
      {img:require("@/assets/model/V71/mobile/v71_home_m.png"),id:'v71'},

    ]
    };
  },
  props: {},
  computed: {
    ...mapGetters("tcp", ["isLogged"]),
    ...mapGetters("global", ["lobbyInfo", "version", "staticPic"]),
  },
  created() {
    for(var obj of this.datas){
      this.srcList.push(obj.img)
    }

    var form = JSON.parse(localStorage.getItem('form'))
    if(form){
     for(var i=0;i<this.datas.length;i++){
      if(form.mobile==this.datas[i].id){
        this.selectIndex = i
      }
     }
    }
    
  },
  watch: {},
  methods: {
    ...mapActions("user", ["autoLogin"]),
    ...mapActions("global", ["init"]),
    choose(index){
      this.selectIndex=index
      bus.emit('mobileChoose',this.datas[index])
    }
  },
};
</script>

<style scoped lang="scss">
.page-template {
  background: #fff;
  min-height: calc(var(937px, 100vh) - env(safe-area-inset-bottom));
  padding-top: 60px;
  position: relative;
  z-index: 1;
}

.btn-select {
  flex: 0 0 100%;
}
.btn-select:hover {
  animation: fangda 0.5s;
  animation-fill-mode: forwards;
}
@keyframes fangda {
  0% {
    opacity: 1;
    scale: 1;
  }

  100% {
    opacity: 0.8;
    scale: 1.1;
  }
}
.k-btn.btn-white {
  background: #fff;
  border-color: #fff;
  color: #0d0c22;
}
.btn-white {
  position: fixed;
  right: 100px;
  top: 200px;
  z-index: 3000;
}
.current {
  background: url("@/assets/pc/select.png");
  height: 30px;
  position: absolute;
  right: -10px;
  top: 5px;
  width: 30px;

  z-index: 2;
}
.template-item {
  margin-bottom: 26px;
  margin-right: 16px;
  position: relative;
}

.template-item:nth-child(5n) {
  margin-right: 0;
}
.template-main {
  padding-bottom: 20px;
  padding-top: 50px;
}
.k-btn.btn-outline-white {
  background: rgba(0, 0, 0, 0.01);
  border-color: #fff;
  color: #fff;
}
.image {
  height: 100%;

  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top center;
  object-position: top center;

  width: 100%;
  z-index: 1;
}

.template-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  .template-info {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .template-cover {
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    cursor: pointer;
    margin-bottom: 8px;
    overflow: hidden;
    position: relative;

    .link-cover {
      align-items: center;
      background: rgba(0, 0, 0, 0.8);
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;

      pointer-events: none;
      position: absolute;
      top: 0;
      transition: 0.3s;
      width: 100%;
      z-index: 10;
      .k-btn {
        width: 80%;
      }
    }
  }

  .template-name {
    color: #383838;
    font-size: 14px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  .template-item.mobile-item {
    width: calc(20% - 12.8px);
  }
}
.main-wrap {
  /*margin-left: auto;
  margin-right: auto;
  width: 90%;
  margin: 0 auto;
  max-width: 1140px;
  padding: 0 16px;
  width: 100%;*/
}
</style>