<template>
	<router-view></router-view>

</template>



<script>
	
	export default {
		name: 'app',
		components: {
			
		},
		provide () {
		   return {
					  scale:1
		   }
		 },
		 created(){
			
		 },
		methods: {
			
		}
	}
</script>

<style lang="scss">
	
*, :after, :before {
	box-sizing: border-box;
  }
  *{
	margin: 0;
    padding: 0;
  }

	html, body {
		margin: 0;
		padding: 0;
		 height: 100%;
		-webkit-font-smoothing: antialiased;
  	  color: #0d0c22;
	  }
	
	  #app {
		 height: 100%;
	  }
</style>
