<template>
  <div class="root">
    
    <router-view></router-view>

   
  </div>
</template>

<script>
import tools from "@/utils/tools";

import { bus, busName } from "@/components/core/bus";


import { http } from "@/components/protocol/api";
import { req, qs } from "@/utils/request";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {  },
  data() {
    return {
      isIos: false,
			showDownload: true

	};
  },
  props: {},
  computed: {
    ...mapGetters("tcp", ["isLogged"]),
    		...mapGetters("global", ["lobbyInfo", "version", "staticPic"]),

  },
  created() {

  
  },
  methods: {
    ...mapActions("user", ["autoLogin"]),
    ...mapActions("global", ["init"]),
    
  },
};
</script>

<style scoped lang="scss">
.root {
  background-color: #fff;
  padding-top:58px;
  height: calc(100% - 58px);
  //padding-bottom: 36px;
  
}
</style>