import { createApp } from 'vue'
import './registerServiceWorker'
import store from './store'
import log from '@/utils/logger'
import i18n from './locales/i18n'
import router from './router'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

  
import App from './App.vue'


const app = createApp(App)


import tools from '@/utils/tools'
//import action from '@/components/core/action'
app.config.globalProperties.$tools = tools
//app.config.globalProperties.$act = action


app.use(store)
app.use(ElementPlus)

app.use(router);
app.use(i18n);


app.mount('#app')
