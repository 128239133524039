
let RootView = require('../views/pc/RootView.vue').default

let home = require('../views/pc/components/home.vue').default
let mobile = require('../views/pc/components/mobile.vue').default
let pc = require('../views/pc/components/pc.vue').default
let ContactUs = require('../views/pc/components/ContactUs.vue').default


let gameTable = require('../views/pc/components/gameTable.vue').default
let grate = require('../views/pc/components/grate.vue').default


let rootChilren = [
	{
        path: '/',
        name: 'home',
        component: home
      },
	  {
        path: '/mobile',
        name: 'mobile',
        component: mobile
      },
	  {
        path: '/pc',
        name: 'pc',
        component: pc
      },
	  {
        path: '/gameTable',
        name: 'gameTable',
        component: gameTable
      },
      {
        path: '/ContactUs',
        name: 'ContactUs',
        component: ContactUs
      },
      {
        path: '/grate',
        name: 'grate',
        component: grate
      },

      
      
	
]

		
let routes = [
	{
		path: '/',
		name: 'root',
		redirect: '/home',
		component: RootView ,
		children: rootChilren
	},
	{
		  path: '/:pathMatch(.*)*',
		redirect:'/'
	}
]
export {
	routes,
} 