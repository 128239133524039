<template>
  <div class="page-template">
    <div class="template-main main-wrap">
      <div class="template-list">
        <div class="template-item mobile-item "  v-for="(item,index) in datas" >
         
          <div class="template-name">{{$t(item.id)}}</div>
          <div class="template-cover" @mouseenter="hoverIndex=index" @mouseleave="hoverIndex=null">
            <el-image @click="open()" class="image" :hide-on-click-modal="true" @show="showChoose=true" :src="item.img" 
           
            fit="cover" />
            <div class="link-cover" v-if="hoverIndex==index">
              <button class="k-btn btn-outline-white">{{$t('预览')}}</button>
            </div>
          </div>
          <div class="template-info" @click="toContact()" >
            <button class="k-btn btn-select">{{$t('我要定制')}}</button>
           </div>
         
        </div>
      </div>
    </div>

    

  </div>
</template>

<script>
import tools from "@/utils/tools";

import { bus, busName } from "@/components/core/bus";

import { http } from "@/components/protocol/api";
import { req, qs } from "@/utils/request";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {},
  data() {
    return {
      hoverIndex:null,
      current:null,
      selectIndex:null,
      srcList:[],
      showChoose:false,
      datas:[{img:require("@/assets/V61.png"),id:'精品模板1'}
      

    ]
      
    };
  },
  props: {},
  computed: {
    ...mapGetters("tcp", ["isLogged"]),
    ...mapGetters("global", ["lobbyInfo", "version", "staticPic"]),
  },
  created() {
   

   
  },
  watch: {},
  methods: {
    ...mapActions("user", ["autoLogin"]),
    ...mapActions("global", ["init"]),
    toContact(){
      this.$router.push({name:"ContactUs"})
    },
    open(){
      window.open('http://v61.newwynn.com/#/')
    }
  },
};
</script>

<style scoped lang="scss">
:deep(.el-image){
  img{
    object-position: top center;
  }
  
}
.page-template{

    background: #fff;
    min-height: calc(var(937px, 100vh) - env(safe-area-inset-bottom));
    padding-top: 60px;
    position: relative;
    z-index: 1;
}

 .btn-select {
  flex: 0 0 100%;
}
.btn-select:hover{
  animation: fangda 0.5s ;
  animation-fill-mode: forwards;

}
@keyframes fangda {
  0% {
    opacity:1;
    scale: 1;
  }

  100% {
    opacity:0.8;
    scale: 1.1;
  }
}
.k-btn.btn-white {
  background: #fff;
  border-color: #fff;
  color: #0d0c22;
 
}
.btn-white{
  position:fixed;
  right:100px;
  top:200px;
  z-index:3000;

}
.current {
  background:url('@/assets/pc/select.png');
  height: 30px;
  position: absolute;
  right: -10px;
  top: 5px;
  width: 30px;
  
  z-index: 2;
}
 .template-item {
  margin-bottom: 26px;
  margin-right: 16px;
  position: relative;

}
.template-item:nth-child(4n) {
  margin-right: 0;
}
.template-main {
  padding-bottom: 20px;
  padding-top: 50px;
}
.k-btn.btn-outline-white {
  background: rgba(0, 0, 0, 0.01);
  border-color: #fff;
  color: #fff;
}
.image{
  height: 100%;
     
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top center;
  object-position: top center;
  position: absolute;
  top: 0;
  width: 100%;
  left:0;
 
  z-index:1;
}
.template-cover:before {
    content: "";
    float: left;
    padding-bottom: 80%;
    width: 100%;
}
.template-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  .template-info {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
 .template-cover:before {
  content: "";
  float: left;
  padding-bottom: 80%;
  width: 100%;
}
  .template-cover {
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    cursor: pointer;
    margin-bottom: 8px;
    overflow: hidden;
    position: relative;
   
    .link-cover {
      align-items: center;
      background: rgba(0, 0, 0, 0.8);
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      
      pointer-events: none;
      position: absolute;
      top: 0;
      transition: 0.3s;
      width: 100%;
      z-index:10;
      .k-btn {
        width: 80%;
      }
    }
  }

  
  .template-name {
    color: #383838;
    font-size: 14px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  .template-item.mobile-item {
    width: calc(25% - 12px);
  }
}
.main-wrap {
  /*margin-left: auto;
  margin-right: auto;
  width: 90%;
  margin: 0 auto;
  max-width: 1140px;
  padding: 0 16px;
  width: 100%;*/
}
</style>