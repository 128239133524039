
import tools from "@/utils/tools"
import log from '@/utils/logger'
function initNative() {
    if (tools.platform() == "ios") {
        console.log = function (msg) {
            post({
                method: "log",
                params: {
                    msg: msg
                },
            })
        }
    }
}

function post(data) {
    if (typeof xapp != "undefined")
        xapp.postMessage(JSON.stringify(data))
    else
        console.log("current platform is not on mobile device")
}

if (typeof xapp != "undefined") {
    initNative();
}
let cbMap = {}
const native = {
    saveImage: function (base64, name) {
        post({
            method: "saveImage",
            params: {
                image: base64,
                name: name,
            },
        })
    },
    launch(url, name, mode) {
        post({
            method: "launch",
            params: {
                url: url,
                name: name,
                mode: mode,
            }
        })
    },
    openWebPage(url, isPortrait = true, showFloat = true) {
        post({
            method: "openWebPage",
            params: {
                url: url,
                isPortrait: isPortrait,
                showFloat: showFloat,
            }
        })
    },
    exit() {
        post({
            method: "exit",
        })
    },
    getCompileTime() {
        return new Promise((reslove, reject) => {
            cbMap["getCompileTime"] = reslove
            post({
                method: "getCompileTime",
            })
        })
    },
    backgroundColor(i) {
        post({
            method: "backgroundColor",
            params: { color: i, },
        })
    },
    getBackgroundColor(){
        return new Promise((reslove, reject) => {
            cbMap["getBackgroundColor"] = reslove
            post({
                method: "getBackgroundColor",
            })
        })
    },
}
const nativeReceive = {
    onResume: () => {
        log.info("native onResume")
    },
    onPause: () => {
        log.info("native onPause")
    },
}
window.flutterCallback = function (str) {
    log.info(str)
    let params = JSON.parse(str)
    if (typeof cbMap[params["method"]] == "function") {
        cbMap[params["method"]](params["data"])
        delete cbMap[params["method"]]
    } else if (typeof nativeReceive[params["method"]] == "function") {
        nativeReceive[params["method"]](params["data"])
    } else {
        log.error("not found native receive method:" + str)
    }
}
export default native

