<template>
  <div class="home">
    <section class="home-top">
      <div class="main-wrap">
        <div class="left">
          <div class="small-text-tag">{{ $t("B2B 平台供应商") }}</div>
          <h1>{{ $t("iGaming 界的奢华巨人") }}</h1>
          <p>
            {{ $t("tips1") }}
          </p>
          <div class="button-group">
            <button class="k-btn btn-l btn-primary" @click="toPage('ContactUs')">{{ $t("立即联系") }}</button
            ><button class="k-btn btn-l btn-outline-primary" @click="toPage('mobile')">
              {{ $t("选择模板") }}
            </button>
          </div>
        </div>
        <div class="right">
          <img src="@/assets/pc/shouye_zs.png" alt="top img" loading="lazy" />
        </div>
      </div>
    </section>
    <section class="home-partner">
      <div class="main-wrap">
        <h2 class="home-partner-title">
          {{ $t("WYNN 与世界知名游戏厂商建立良好合作关係，联手共创双赢") }}
        </h2>
        <swiper
          :modules="modules"
          :autoplay="{
            autoplay: true,
            delay: 3000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }"
          :free-mode="true"
          :slides-per-view="8"
          :slides-per-group="1"
          :space-between="0"
        >
          <swiper-slide
            ref="swipeSlideRef"
            v-for="(item, index) in factoryList"
            :key="item"
          >
            <img ref="swipeSlideImgRef" :src="item" alt="" />
          </swiper-slide>
        </swiper>
      </div>
    </section>
    <section class="home-feature">
      <div class="main-wrap">
        <div class="feature-title">{{ $t("WYNN 是您的首选") }}</div>
        <p class="feature-desc" v-html="$t('tips2')"></p>
        <div class="feature-list">
          <div class="feature-item" v-for="(item, index) in recommondList">
            <img :src="item.img" alt="feature1" loading="lazy" />
            <h4>{{ $t(item.title) }}</h4>
            <p>{{ $t(item.des) }}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="home-about">
      <div class="main-wrap">
        <div class="about-title">
          {{ $t("开创跨世代平台，引领业界奢华新风尚") }}
        </div>
        <div class="about-desc">
          {{ $t("tips3") }}
        </div>
        <ul class="about-list">
          <li class="about-item" v-for="item in aboutList">
            <div class="item-img">
              <img :src="item.img" loading="lazy" />
            </div>
            <div class="item-main-content">
              <h4 class="item-title">{{ $t(item.title) }}</h4>
              <p class="item-desc">
                \
                {{ $t(item.des) }}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <section class="home-invite">
      <div class="video-mask"></div>
      <div class="main-wrap">
        <h3 v-html="$t('让我们共同期待 无限可能的全球 AI 革命')"></h3>
        <p>
          {{ $t("tips7") }}
        </p>
      </div>
    </section>
    <section id="homeDemo" class="home-demo">
      <div class="main-wrap">
        <h2 class="home-demo-title">{{$t('WYNN 综合包网优势')}}</h2>
        <p class="home-demo-desc">
          {{ $t('tips8') }}
        
        </p>
        <div class="home-demo-main">
          <div class="mobile-frame">
            <img
              src="@/assets/show.gif"
              alt="mobile-frame"
              loading="lazy"
            />
          </div>
          <div class="home-demo-list">
            <div class="demo-item" v-for="item in ysList">
              <div class="main-content">
                <h4>{{ $t(item.title) }}</h4>
                <p>
                  {{ $t(item.des) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="home-contact">
      <div class="main-wrap">
        <div class="home-contact-main">
          <h3>{{$t('立即联系我们以获取最新优惠资讯')}}</h3>
          <p>
            {{ $t('tips9') }}
          </p>
          <div class="button-group">
            <button class="k-btn btn-l btn-primary" @click="toPage('ContactUs')">{{$t('立即联系')}}</button
            ><button class="k-btn btn-l btn-outline-primary" @click="toPage('mobile')">{{$t('选择模板')}}</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import tools from "@/utils/tools";

import { bus, busName } from "@/components/core/bus";

import { http } from "@/components/protocol/api";
import { req, qs } from "@/utils/request";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import { FreeMode } from "swiper";
import { Autoplay } from "swiper";

import "swiper/swiper.css";
export default {
  components: { Swiper, SwiperSlide },
  data() {
    return {
      factoryList: [],
      aboutList: [
        {
          img: require("@/assets/pc/jingy_icon.png"),
          title: "经营风险抵抗",
          des: "tips4",
        },
        {
          img: require("@/assets/pc/youhu_icon.png"),
          title: "平台优化指标",
          des: "tips5",
        },
        {
          img: require("@/assets/pc/gexin_icon.png"),
          title: "技术革新发展",
          des: "tips6",
        },
      ],
      ysList: [
        { title: "快速网站搭建", des: "ys1" },
        { title: "费率透明公道", des: "ys2" },
        { title: "后台强大稳定", des: "ys3" },
        { title: "实时数据风控", des: "ys4" },
        { title: "支持多国语系", des: "ys5" },
        { title: "服务即时专业", des: "ys6" },
        { title: "运营需求协助", des: "ys7" },
        { title: "严格资安管理", des: "ys8" },
        { title: "多元支付渠道", des: "ys9" },
        { title: "高效代理系统", des: "ys10" },
      ],
      recommondList: [
        {
          img: require("@/assets/pc/haop_icon.png"),
          title: "广受业界好评",
          des: "超过1000+ 合作伙伴，业界累积无数良好的信用及好评，值得信赖",
        },
        {
          img: require("@/assets/pc/xiezhu_icon.png"),
          title: "全面运营协助",
          des: "利用大数据分析，超前部署运营需求，协助客户达到品牌目标，迈向巅峰",
        },
        {
          img: require("@/assets/pc/yuyan_icon.png"),
          title: "多国语系支持",
          des: "含括中、英、马、泰、越、菲、印、日、韩等主要语系，支持全球市场，占尽先机",
        },
        {
          img: require("@/assets/pc/huobi_icon.png"),
          title: "多国货币支援",
          des: "支持串接多国货币支付功能，全方位支援运营，把网站推到全世界，破亿收入不是梦",
        },
        {
          img: require("@/assets/pc/kehu_icon.png"),
          title: "专业客户服务",
          des: "提供最及时且最优质的服务给所有尊贵的客户，诚信互动，绝不怠慢",
        },
        {
          img: require("@/assets/pc/fanghu_icon.png"),
          title: "完善资安防护",
          des: "重视客户隐私，採取最高级别的资安防护系统来保护所有客户，让安全漏洞无所遁形",
        },
        {
          img: require("@/assets/pc/xitong_icon.png"),
          title: "后台系统强稳",
          des: "技术层面要求极高，后台系统维护和更新都有专人全天候负责，稳定负责",
        },
        {
          img: require("@/assets/pc/kaifa_icon.png"),
          title: "全端系统开发",
          des: "多种设备支持，电脑版，H5版，APP手机版，稳定系统一次拥有",
        },
      ],
      modules: [FreeMode, Autoplay],
    };
  },
  props: {},
  computed: {
    ...mapGetters("tcp", ["isLogged"]),
    ...mapGetters("global", ["lobbyInfo", "version", "staticPic"]),
  },
  created() {
    for (var i = 1; i <= 22; i++) {
      let img = require("@/assets/pc/factory/cs" + i + ".png");
      this.factoryList.push(img);
    }
  },
  methods: {
    ...mapActions("user", ["autoLogin"]),
    ...mapActions("global", ["init"]),
    toPage(name){
      this.$router.push({name:name})
    }

  },
};
</script>

<style scoped lang="scss">
.swiper,
swiper-container {
  display: block;
  list-style: none;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  padding: 0;
  position: relative;
  z-index: 1;
}
.home {
  background: #fff;
  min-height: calc(var(937px, 100vh) - env(safe-area-inset-bottom));
  padding-top: 60px;
  position: relative;
  z-index: 1;
   .home-contact{
    .home-contact-main {
      align-items: center;
      background: #f5f6ff;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 40px;
       .button-group {
        display: flex;
        gap: 10px;
        .k-btn {
          min-width: 150px;
      }
    }
      >p {
        color: #383838;
        line-height: 1.6;
        margin: 0 auto;
        padding: 40px 0;
        text-align: center;
        width: 85%;
    }
      >h3 {
        font-size: 36px;
        font-weight: 700;
        line-height: 1.2;
        margin: 0 auto;
        text-align: center;
        width: 80%;
    }
    }
   }
  .home-demo {
    padding: 80px 0;
    .home-demo-main {
      margin-bottom: 40px;
      position: relative;
      .home-demo-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        z-index: 2;
        .demo-item {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          width: 50%;
          .main-content {
            border: 2px solid #f5f6ff;
            border-radius: 20px;
            padding: 20px;
            width: 60%;
            > p {
              color: #383838;
              font-size: 13px;
              line-height: 1.6;
            }
            > h4 {
              font-weight: 500;
              line-height: 1.4;
              margin-bottom: 10px;
            }
          }
        }
        .demo-item:nth-child(2n) {
          align-items: flex-end;
          text-align: right;
        }
      }
      .mobile-frame {
        align-items: center;
        display: flex;
        justify-content: center;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 30%;
      }
    }

    .home-demo-desc {
      color: #383838;
      font-weight: 400;
      line-height: 1.6;
      margin: 0 auto 40px;
      text-align: center;
      width: 80%;
    }
    .home-demo-title {
      font-size: 40px;
      font-weight: 700;
      margin: 0 auto 20px;
      text-align: center;
      width: 80%;
    }
  }
  .home-invite {
    background-color: #000;
    color: #fff;
    padding: 80px 0;
    position: relative;
    .main-wrap {
      display: flex;
      position: relative;
      z-index: 3;
      > p {
        font-size: 14px;
        line-height: 1.6;
      }
      > * {
        flex: 1 1;
      }
      > h3 {
        font-size: 40px;
        font-weight: 700;
        line-height: 1.2;
      }
    }
  }
  .home-about {
    padding: 80px 0;
    text-align: center;
    .about-list {
      display: flex;
      gap: 90px;
      li {
        align-items: center;
        display: flex;
        flex: 1 1;
        flex-direction: column;
        .item-desc {
          color: #383838;
          font-size: 13px;
          line-height: 1.6;
          text-align: left;
        }
        .item-title {
          font-weight: 500;
          line-height: 1.4;
          margin-bottom: 10px;
        }
        .item-img {
          align-items: center;
          display: flex;
          height: 100px;
          justify-content: center;
          margin-bottom: 10px;
          width: 100px;
          img {
            display: block;
            width: 100%;
          }
        }
      }
    }
    .about-desc {
      color: #383838;
      font-weight: 400;
      line-height: 1.6;
      margin-bottom: 30px;
    }
    .about-title {
      font-size: 40px;
      font-weight: 700;
      line-height: 1.2;
      margin-bottom: 20px;
    }
  }

  .home-feature {
    background: #000;
    min-height: calc(100vh - 60px);
    position: relative;
    .feature-list {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      .feature-item {
        background: #3b3b3b;
        border-radius: 20px;
        padding: 20px;
        width: calc(25% - 22.5px);
        > p {
          font-size: 13px;
          line-height: 1.6;
          opacity: 0.8;
        }
        > h4 {
          font-weight: 500;
          line-height: 1.4;
          margin-bottom: 10px;
        }
        img {
          display: block;
          margin-bottom: 15px;
          opacity: 0.6;
          width: 40%;
        }
      }
    }
    .feature-desc {
      line-height: 1.4;
      margin: 0 auto 40px;
      opacity: 0.8;
      text-align: center;
      width: 85%;
    }
    .feature-title {
      font-size: 40px;
      font-weight: 700;
      margin: 0 auto 20px;
      text-align: center;
      width: 80%;
    }
    .main-wrap {
      color: #fff;
      padding: 80px 0;
      position: relative;
      z-index: 3;
    }
  }

  .home-partner {
    margin-bottom: 60px;
    text-align: center;
    .home-partner-title {
      color: #383838;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.4;
      margin-bottom: 20px;
    }
  }
  .main-wrap {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
  .home-top {
    background-position: 110% 50%;
    background-repeat: no-repeat;
    background-size: auto 70%;
    .left {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 40px;
      width: 45%;
      .button-group {
        align-items: center;
        display: flex;
        gap: 20px;
        > * {
          min-width: 130px;
        }
      }
      > p {
        color: #383838;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.5;
        margin-bottom: 30px;
      }
      > h1 {
        font-size: 60px;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 30px;
      }
      .small-text-tag {
        background: #f5f6ff;
        border-radius: 50px;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 20px;
        padding: 10px 20px;
      }
    }
    .right {
      width: 55%;
      > img {
        display: block;
        width: 100%;
      }
    }
    .main-wrap {
      align-items: center;
      display: flex;
      min-height: calc(100vh - 60px);
      padding: 40px 0 60px;
    }
  }
}
</style>

<style>
.main-wrap {
  margin: 0 auto;
  max-width: 1140px;
  padding: 0 16px;
  width: 100%;
}
.k-btn.btn-primary {
  background: #232320;
  border-color: #232320;
  color: #fff;
}
.k-btn {
  align-items: center;
  border: 1px solid #cecece;
  border-radius: 6px;
  color: #0d0c22;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  height: 40px;
  justify-content: center;
  padding: 0 16px;
  transition: opacity 0.5s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn-outline-primary {
  background: transparent;
  border-color: #232320;
  color: #232320;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
</style>