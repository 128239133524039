<template>
  <div class="page-contactus">
    <div class="main-contactus">
      <h2 class="page-title">{{$t('联系我们')}}</h2>
      <div class="business-qr-wrap">
        <div>
          <div class="business-qr">
            <img src="@/assets/code.png" alt="business-avatar" />
          </div>
          <div class="business-name">NEWWYNN88</div>
          <div class="business-title">{{$t('商务总监')}}</div>
          <p class="contact-desc">
            {{$t('如果您对我们的服务有任何疑问，请随时与我们联系')}}
            。
          </p>
          <div class="sns-group">
            <div>
              <a href="https://t.me/newwynn88" target="_blank" rel="noreferrer"
                ><img src="@/assets/tg_icon.png" alt="Telegram" />@NEWWYNN88</a
              >
            </div>
          </div>
        </div>
        <!-- <div>
          <div class="business-qr">
            <img src="/img/contact_WYNN_qr.webp" alt="business-avatar" />
          </div>
          <div class="business-name">WYNN Sales</div>
          <div class="business-title">官方商務號</div>
          <p class="contact-desc">请认准官方网站账号，以免上当受骗！</p>
          <div class="sns-group">
            <div>
              <a href="https://t.me/WYNN_Sales" target="_blank" rel="noreferrer"
                ><img src="/img/telegram.svg" alt="Telegram" />@WYNN_Sales</a
              >
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import tools from "@/utils/tools";

import { bus, busName } from "@/components/core/bus";

import { http } from "@/components/protocol/api";
import { req, qs } from "@/utils/request";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {},
  data() {
    return {};
  },
  props: {},
  computed: {},
  created() {},

  methods: {},
};
</script>

<style scoped lang="scss">
.page-contactus {
  padding: 50px 0;
  .main-contactus {
    align-items: center;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    max-width: 1140px;
    min-height: calc(90vh - 100px);
    text-align: center;
    width: calc(100% - 40px);
    .sns-group {
      align-items: center;
      display: flex;
      gap: 10px;
      justify-content: center;
     >div a {
        align-items: center;
        border: 1px solid #000;
        border-radius: 6px;
        color: #0d0c22;
        cursor: pointer;
        display: flex;
        gap: 5px;
        justify-content: center;
        min-width: 150px;
        padding: 10px;
        text-decoration: none;
        transition: .3s ease;
        >img {
          display: block;
          height: 20px;
      }
    }
  }
    .contact-desc {
      color: #9e9e9e;
      line-height: 1.4;
      margin-bottom: 15px;
      width: 80%;
  }
    .business-title {
      color: #9e9e9e;
      font-size: 15px;
      margin-bottom: 15px;
  }
    .business-name {
      font-size: 28px;
      margin-bottom: 5px;
  }
    .business-qr {
      margin-bottom: 10px;
      img {
        display: block;
        width: 140px;
    }
  }
    .business-qr-wrap {
      display: flex;
      gap: 40px;
      >* {
        align-items: center;
        border: 2px solid #f5f6ff;
        border-radius: 20px;
        display: flex;
        flex: 1 1;
        flex-direction: column;
        justify-content: center;
        padding: 50px 20px;
        transition: border-color .3s ease;
      }
    }
    .page-title {
      font-size: 36px;
      font-weight: 700;
      margin-bottom: 50px;
  }
  }
}
</style>