<template>
  <div class="root">
    <Nav></Nav>
    <router-view></router-view>
    <footer class="k-footer" :class="isGrate()?'fixBottom':''">
      <div class="main-wrap">
        <div class="copyright" >© WYNN. All rights reserved.</div>
      </div>
    </footer>
    <Set></Set>
  </div>
</template>

<script>
import tools from "@/utils/tools";

import { bus, busName } from "@/components/core/bus";
let Nav = require("@/views/pc/components/Nav.vue").default;

let Set = require("@/views/pc/components/Set.vue").default;
import i18n from '@/locales/i18n'


import { http } from "@/components/protocol/api";
import { req, qs } from "@/utils/request";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: { Nav ,Set},
  data() {
    return {
      isIos: false,
      showDownload: true,
    };
  },
  props: {},
  computed: {
    ...mapGetters("tcp", ["isLogged"]),
    ...mapGetters("global", ["lobbyInfo", "version", "staticPic"]),
  },
  created() {
   if(localStorage.getItem('language')){
    i18n.global.locale = localStorage.getItem('language')
   }
    localStorage.removeItem('form')
  },
  methods: {
    ...mapActions("user", ["autoLogin"]),
    ...mapActions("global", ["init"]),
    isGrate(){
      if(this.$route.path=='/grate'){
        return true
      }else {
        return false
      }
      
    }
  },
};
</script>

<style scoped lang="scss">
.main-wrap {
  margin: 0 auto;
  max-width: 1140px;
  padding: 0 16px;
  width: 100%;
}
.fixBottom{
  position: fixed;
  bottom:0;
  left:0;
  width:100%;
}
.k-footer {
  background: #fff;
  padding: 30px 0;
  .copyright {
    color: #0d0c22;
    text-align: center;
}
}
.root {
  background-color: #fff;

  //padding-bottom: 36px;
}
</style>

<style>
.canClick{
  cursor: pointer;
}
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
}

.k-react-select .el-select .el-input.is-focus .el-input__wrapper {
  box-shadow: none !important;
 
}

.k-react-select .el-select .el-input__wrapper.is-focus  {
  box-shadow: none !important;
  background-color: #f5f7f9;
}

.el-input__wrapper{
  background-color: #f5f7f9 !important; 
  box-shadow: none !important;
}
.el-tag {
  background-color: rgb(35, 35, 32) !important;
  color:#fff !important;
}
.el-tag .el-tag__close {
  color: #fff !important;
}

.el-input__inner{
  height:40px !important;
  color:#000 !important;
}
</style>