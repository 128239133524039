import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

import { user } from '@/store/module/user'
import { global } from '@/store/module/global'
import { tcp } from '@/store/module/tcp'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['user']
}) 
const store = createStore({
  modules: {
    user,
    global,
	tcp
  },
  plugins: [vuexLocal.plugin]
})
store.dispatch('user/init')
export default store

