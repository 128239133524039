import { createRouter, createWebHashHistory } from 'vue-router'
import tools from '@/utils/tools'

let module = tools.isMobile() ? require('./mobile') : require('./pc')

const routes = [
 
  ...module.routes
]



const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior (to, from, savePositon) {
    return { top: 0 }
  },
  routes
})

export default router
