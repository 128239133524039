<template>
  <div class="kheader">

    
    <div class="k-header-inner main-wrap">
      <div class="left" @click="toHome()">
        <img class="k-logo" src="@/assets/logo.png" alt="TKtech" />
      </div>
      <div class="center">
        <div class="k-navigation">
          <ul>
            <li class="" v-for="(item,index) in navList" @click="toPage(item,index)" :class="{current:index==navIndex}">{{$t(item.title) }}</li>
           
          </ul>
        </div>
      </div>
      <div class="right">
        <button class="k-btn btn-outline-primary btn-contact" @click="toYs()">{{$t('画面演示')}}</button
        ><button class="k-btn btn-primary btn-contact" @click="toContact()">{{$t('联系我们')}}</button>
        <div class="lang-select" @change="change">
          <select >
            <option v-for="(item,index) in langList" :value="item.lang">{{item.title}}</option>
           
           
          </select>
          <div class="lang-toggle">
            <img src="@/assets/english_gq.png" v-if="langIndex==0" alt="" />

            <img src="@/assets/yuenan_gq.png" v-if="langIndex==1" alt="" />
            <img src="@/assets/chinese_gq.png" v-if="langIndex==2" alt="" />

            <span>{{langList[langIndex].title}}</span
            >
            <img src="@/assets/arrow.png" class="arrow" alt="">
          </div>
        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
import tools from "@/utils/tools";

import { bus, busName } from "@/components/core/bus";

import { http } from "@/components/protocol/api";
import { req, qs } from "@/utils/request";
import { mapGetters, mapActions, mapMutations } from "vuex";
import i18n from '@/locales/i18n'

export default {
  components: {},
  data() {
    return {
      langList:[{title:'English',lang:'en'},{title:'Tiếng Việt',lang:'vi'},{title:'简体中文',lang:'cn'}],
      langIndex:0,
      navIndex:0,
      navList:[{title:'首页',to:'home'},{title:'手机版',to:'mobile'},{title:'桌面版',to:'pc'},{title:'游戏大厅',to:'gameTable'},{title:'精品定制',to:'grate'}]
    };
  },
  props: {},
  computed: {
    ...mapGetters("tcp", ["isLogged"]),
    ...mapGetters("global", ["lobbyInfo", "version", "staticPic"]),
  },
  created() {
    if(localStorage.getItem('language')){
      for(var i=0;i<this.langList.length;i++){
        if(this.langList[i].lang==localStorage.getItem('language')){
          this.langIndex = i
        }
      }
    }

    if (this.$route.path == '/mobile') {
					this.navIndex = 1
				} else if (this.$route.path == '/pc') {
					this.navIndex = 2
				} else if (this.$route.path == '/gameTable') {
					this.navIndex = 3

				} else if (this.$route.path == '/grate') {
					this.navIndex = 4

				} else {
					this.navIndex = 0

				}

  },
  watch:{
    $route(to, from) {
				if(to.path=='/mobile'){
          this.navIndex = 1
        }else if(to.path=='/pc'){
          this.navIndex = 2
        }else if(to.path=='/gameTable'){
          this.navIndex = 3
        }else if(to.path=='/grate'){
          this.navIndex = 4
        }else {
          this.navIndex = 0
        }
			}
  },
  methods: {
    ...mapActions("user", ["autoLogin"]),
    ...mapActions("global", ["init"]),
    toHome(){
      this.navIndex = 0
      this.$router.push('/')
    },
    toYs(){
      this.$router.push('/')
      setTimeout(() => {
        document.documentElement.scrollTo({top:2700})

}, 100);
    },
    toPage(val,index){
      if(val.to.length){
        this.navIndex = index
        this.$router.push({name:val.to,params:{
          type:val.type
        }})

      }
    },
    toContact(){
      this.$router.push('/ContactUs')
    },
    change(e){
      localStorage.setItem('language',e.target.value)
      i18n.global.locale = e.target.value
      for(var i=0;i<this.langList.length;i++){
        var obj = this.langList[i]
        if(e.target.value==obj.lang){
          this.langIndex = i
          break
        }
      }
     
    }
  },
};
</script>

<style scoped lang="scss">


.lang-select {
  position: relative;
  .lang-toggle {
    align-items: center;
    display: flex;
    font-size: 14px;
    width: 130px;

    .arrow{
      width:13px;
      margin-left:auto;
  }
    img {
      border-radius: 50%;
      margin-right: 6px;
      width: 25px;
  }
}
  select {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
}

.kheader {
  background: #fff;
  height: 60px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  .right {
    margin-left: auto;
}
  .center{
    .k-navigation ul {
      display: flex;
      gap: 12px;
      height: 100%;
      .current{
        font-weight: 700;
      }
      li {
        align-items: flex-end;
        cursor: pointer;
        display: flex;
        font-size: 16px;
        height: 36px;
        padding: 0 10px;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        
    }
  }
  }
  .left{
     .k-logo {
      cursor: pointer;
      width: 131px;
      margin-right: 24px;
    }
  }
  .k-header-inner>* {
    align-items: center;
    display: flex;
    gap: 20px;
}
  .k-header-inner {
    align-items: center;
    display: flex;
    height: 60px;
  }
  .main-wrap {
    margin: 0 auto;
    max-width: 1140px;
    padding: 0 16px;
    width: 100%;
  }
}
</style>