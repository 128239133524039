
let RootView = require('../views/mobile/RootView.vue').default

let home = require('../views/mobile/components/home.vue').default



let rootChilren = [
	{
        path: '/',
        name: 'home',
        component: home
      },
	
]

		
let routes = [
	{
		path: '/',
		name: 'root',
		redirect: '/home',
		component: RootView ,
		children: rootChilren
	},
	{
		  path: '/:pathMatch(.*)*',
		redirect:'/'
	}
]
export {
	routes,
} 